// 自定义common.js公共方法
import { ElLoading } from 'element-plus'
import { ref } from 'vue'
// 定义loading
const loading = ref(false)
// 打开loading
export const openLoading = () => {
  loading.value = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.7)'
  })
}
// 关闭loading
export const closeLoading = () => {
  loading.value.close()
}
