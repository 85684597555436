import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import store from './store/index'
// 引入全局css
import '@/assets/css/index.scss'
// import { createPinia } from 'pinia' // 这个写到store的index.js里面了
// import piniaPluginPersist from 'pinia-plugin-persist' // 这个写到store的index.js里面了
// 国际化
import en from 'element-plus/dist/locale/en.mjs'
// 自定义组件
// import registerDirectives from './directives/index.js'
// 引入ICON
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// 引入打印插件
import print from 'vue3-print-nb'
// 导入抖动shake
import { shake } from '@/directives/shake.js'
// 导入自制组件
import myUI from '@/component'

// 解决导航报错ResizeObserver loop limit exceeded at eval (webpack-internal:///./node_modules/webpack-dev-server/client/overlay.js:296:58)
const debounce = (fn, delay) => {
  let timer = null
  return function () {
    let context = this
    let args = arguments
    clearTimeout(timer)
    timer = setTimeout(function () {
      fn.apply(context, args)
    }, delay)
  }
}
const _ResizeObserver = window.ResizeObserver
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor (callback) {
    callback = debounce(callback, 16)
    super(callback)
  }
}

const app = createApp(App)
shake(app)
// 导入ICON
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
// 定义vue3的全局方法 config配置,global全局,Properties属性
app.config.globalProperties.$say = function (msg) { alert(msg) }
// 把自定义组件的app传过来
// registerDirectives(app)
// 把ElementPlus放到app.use里
app.use(ElementPlus, {
  locale: en
})
// app.use(createPinia())
app.use(myUI)
app.use(router)
app.use(store)
app.use(print).mount('#app')
