import { defineStore } from 'pinia'
// 导入获取菜单的api
import { GetUserMenu } from '@/api/menu'
// 导入路由(动态路由第一步)
import router from '@/router/index.js'
// storeId唯一
export const menus = defineStore({
  id: 'menus',
  state: () => {
    return {
      // 全局菜单数组
      menuList: []
      // age1: 10
    }
  },
  // 计算属性(有缓存,只执行一次)
  getters: {
  },
  // 写方法的，同时支持同步和异步
  actions: {
    // 获取人员所属菜单
    async getUserMenu () {
      // return new Promise(resolve=>{
      // debugger
      const { data } = await GetUserMenu()
      if (data.code === 200) {
        // 更新menus
        this.menuList = { ...data.data }
        // 定义子路由数组
        // console.log(this.menuList)
        // 把菜单进行遍历
        data.data.forEach(item => {
          // 如果当前对象没有children子元素
          if (item.children) {
            // 如果子菜单不为空,加一级路由
            router.addRoute({
              name: item.name,
              path: item.path,
              component: () => import('@/views' + item.component)
            })
            item.children.forEach((menuchild) => {
              // 加一级路由的子路由
              router.addRoute(item.name, {
                name: menuchild.name,
                path: menuchild.path,
                component: () => import('@/views' + menuchild.component)
              })
            })
            // 此处开发完要改，这是进入后的默认主页,现在默认进的是admin里面的user模块
            if (item.name === 'admin') {
              // 给一个默认进入的位置
              router.addRoute(item.name, {
                path: '',
                redirect: '/auth/user',
                name: 'redirect'
              })
            }
          } else {
            // 如果子菜单为空,加一级路由,暂时没有问题
            router.addRoute({
              name: item.name,
              path: item.path,
              component: () => import('@/views' + item.component)
            })
          }
        })
        // getRoutes用来获取路由记录,路由和前面展示的菜单界面数据没有关系
        // console.log(router.getRoutes(), 'getRoutes')
        // 代表承诺promise执行完毕
        // resolve(admin)
      }
    // })
    },
    clearMenuList () {
      this.menuList = []
    }
  }
})
