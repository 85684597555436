import axios from 'axios'
import { myuser } from '@/store/myuser'
import { menus } from '@/store/permission'
import { openLoading, closeLoading } from './loading'
import router from '@/router'
import { ElMessage } from 'element-plus'
import { intranetIP } from '@/utils/IpPort'
// 导出基准地址：将来有一些请求可能不是通过axios发的，比如上传图片的图片服务器
export const baseURL = intranetIP
// export const baseURL = 'http://tjlgcm.xicp.net:58888/'
// 创建axios实例 10分钟
const request = axios.create({
  baseURL,
  timeout: 5000
})
// 添加请求拦截器(拦截成功config默认参，拦截失败error)
// request.interceptors.request.use(() => {}, () => {})
request.interceptors.request.use(config => {
  // 拦截业务逻辑
  // 进行请求配置的修改
  // 如果本地有token，就在头部携带token
  // 1.获取token(access就是token)
  const { myusers } = myuser()
  // 2.判断是否有token
  if (myusers.access) {
    // 3.设置token
    config.headers.Authorization = `Bearer ${myusers.access}`
  }
  return config
}, error => {
  return Promise.reject(error)
})

// 拦截响应

// 添加响应拦截器(响应成功res获取到的数据默认参，响应失败error)
// request.interceptors.response.use(() => {}, () => {})
// 取出res数据,如果是res.data就直接能看到code了
request.interceptors.response.use(res => {
  return res
}, async error => {
  // 401 状态码，失败或者token失效,进入该函数
  // error.response(排除无响应)
  if (error.response && error.response.status === 401) {
    // 获取用户信息
    const { myusers, changeUser } = myuser()
    try {
      const res = await axios({
        method: 'POST',
        url: `${baseURL}/api/geyaliang/refresh/`,
        timeout: 10000,
        data: {
          refresh: myusers.refresh
        }
      })
      changeUser({
        access: res.data.access, // 最新获取的可用 token
        refresh: myusers.refresh, // 还是原来的 refresh
        username: myusers.username,
        id: myusers.id,
        avatar: myusers.avatar,
        dept: myusers.dept,
        dept_owner: myusers.dept_owner, // 部门领导
        name: myusers.name,
        my_groups: myusers.my_groups,
        form_yu_ming: myusers.form_yu_ming,
        liu_lan_liang: myusers.liu_lan_liang
      })
      // return 把 request 的请求结果继续返回给发请求的具体位置
      return request(error.config)
    } catch (error) {
      ElMessage.error('获取认证失败')
      // 清空无效用户信息
      const { clearMyusers } = myuser()
      const { clearMenuList } = menus()
      clearMyusers()
      clearMenuList()
      // 获取当前路由地址，下方有说明
      // const fullPath = encodeURIComponent(router.currentRoute.value.fullPath)
      // 跳转需要传参(当前路由地址)给登录页
      router.push(router.currentRoute.value.fullPath)
      // router.push({ path: fullPath })
    }

    // 当前路由地址
    // 组件里头:'/user?a=10&b=10'  $route.path === /user $route.fullPath(完整路径) === /user?a=10&b=10
    // js模块中: router.currentRoute和$route是一样的效果.fullPath 就是当前路由地址 vue2是这样
    // router.currentRoute 是ref响应式数据  router.currentRoute.value   vue3是这样
    // 需要转码处理特殊字符串的问题，比如&会被自动处理掉(encodeURIComponent转化uri编码，防止解析地址出问题)
    // const fullPath = encodeURIComponent(router.currentRoute.value.fullPath)

    // 3. 跳转需要传参(当前路由地址)给登录页
    // router.push('/?redirectUrl=' + fullPath)
    // router.push('/')
  } else if (error.response && error.response.status === 404) {
    router.push('/')
  } else if (error.response && error.response.data.code === '4001') {
    // 主从表填写不完整或者效验没通过
    const { data } = error.response
    ElMessage({
      showClose: true,
      message: `${data.error},按x关闭或者5秒自动关闭`,
      type: 'warning',
      duration: 5000
    })
  } else if (error.response && error.response.status === 403) {
    // 没有权限的
    const { data } = error.response
    ElMessage.error(data.detail)
  } else if (error.response && error.response.status === 500) {
    console.log(error.response)
    ElMessage.error('服务器错误，找葛亚亮')
  } else {
    const { data } = error.response
    let errorName
    if (data && data.non_field_errors && data.non_field_errors[0] === '无外网访问权限，登录失败') {
      errorName = '无外网访问权限，登录失败'
    } else errorName = JSON.stringify(data)
    ElMessage({
      showClose: true,
      message: `${errorName},按x关闭或者10秒关闭`,
      type: 'warning',
      duration: 10000
    })
  }
  openLoading()
  closeLoading()
  return Promise.reject(error)
})

// 导出
export default request
