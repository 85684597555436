// shake.js
// shake.js文件中导入了shake.css, 笔者用的是vue-cli脚手架, 里面集成了css-loader 所以在js文件中可以导入css
import '@/assets/css/shake.scss'

export const shake = app => {
  app.directive('shake', {
    mounted: el => {
      // 节流, 实现连续点击丝滑抖动
      let shakeOver = true
      el.addEventListener(
        'click',
        e => {
          if (shakeOver) {
            shakeOver = false
            e.target.classList.add('apply-shake')
            let timer = setTimeout(() => {
              clearTimeout(timer)
              e.target.classList.remove('apply-shake')
              shakeOver = true
            }, 820) // 因为动画是820ms播放完毕
          }
        },
        false
      )
    }
  })
}
