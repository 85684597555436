// 导入request
import request from '@/utils/request'
// 导出获取菜单列表

export function GetMenuList (data) {
  return request.get('/api/menu/', { params: data })
}
export function DeleteMenu (id) {
  return request.delete(`/api/menu/${id}/`)
}
// 用户菜单列表
export function GetUserMenu () {
  return request.get('/api/menu/getusermenus/')
}
