import { defineStore } from 'pinia'

// storeId唯一
// export const user = defineStore('user', {
export const myuser = defineStore({
  id: 'myuser',
  state: () => {
    return {
      myusers: {
        refresh: '',
        access: '',
        username: '',
        id: '',
        avatar: '',
        dept: '',
        dept_owner: '', // 部门领导
        name: '',
        my_groups: [],
        form_yu_ming: null
      },
      // 登录后回跳路径
      redirectUrl: ''
    }
  },
  // 计算属性(有缓存,只执行一次)
  getters: {
  },
  // 写方法的，同时支持同步和异步
  actions: {
    // 清除用户信息
    clearMyusers () {
      this.myusers = {
        refresh: '',
        access: '',
        username: '',
        id: '',
        avatar: '',
        dept: '',
        dept_owner: '',
        name: '',
        my_groups: null,
        form_yu_ming: null,
        liu_lan_liang: ''
      }
      localStorage.removeItem('myuser')
      sessionStorage.removeItem('myuser')
    },
    // 获取用户信息
    getUser (data) {
      this.myusers = data
    },
    // 更新用户信息
    changeUser (data) {
      this.myusers = data
    },
    // 修改回跳地址
    setRedirectUrl (url) {
      this.redirectUrl = url
    }
  },

  // 开启数据缓存(数据内容有变化的时候，才会进行持久化存储。如果写了paths,变化的是哪个持久化哪个)
  persist: {
    enabled: true,
    strategies: [
      {
        // 默认会以 store 的 id 作为 key，如下指定
        // key: 'my_user'
        // 数据默认存在 sessionStorage 里,改变存储地点为localStorage
        // storage: localStorage
        // 默认所有 state 都会进行缓存，你能够通过 paths 指定要长久化的字段，其余的则不会进行长久化
        // paths: ['nickName', 'age']
      }
    ]
  }
})
