import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
// // 获取用户所属菜单
import { menus } from '@/store/permission'

const routes = [
  {
    path: '/',
    name: 'Home',
    // component: HomeView
    component: () => import('../views/home/HomeView.vue')
  },
  {
    // path: '/Login',
    path: '/dong_yi',
    name: 'Login',
    component: () => import('../views/user/LoginView.vue')
  },
  {
    path: '/admin',
    // name: 'admin',
    component: () => import('../views/admin/AdminView.vue'),
    children: [
    ]
  }
]

const router = createRouter({
  // 使用hash模式
  // history: createWebHashHistory(),
  // 使用html5模式
  history: createWebHistory(),
  // 页面刷新白屏问题
  // mode取值说明：
  // histroy：URL就像正常的 url，示例：http://localhost:8080/home
  // hash：默认值，会多一个“#”，示例：http://localhost:8080/#/home
  // abstract”：url不变示例：http://localhost:8080
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // 刷新时，滚动条位置还原
  scrollBehavior: () => ({ left: 0, top: 0 })
})

/**
 * 重置路由
 */
export function resetRouter () {
  router.replace({ path: '/' })
  location.reload()
}
// 是0为刷新
let flag = 0
// 导航守卫是在全局生效的(刷新，点链接进入,点按钮切换算，登录以后在网址里直接输入不会经过守卫，所以要在组件里防御)
router.beforeEach(async (to, from, next) => {
  // 获取用户所属菜单 userStore 初始化 必须放在  路由守卫里 才会生效,
  // 如果放在外面const menusStore = menus(store)，但是持久化不生效
  const menusStore = menus()
  // 不属于Login和Home的刷新
  if (flag === 0 && menusStore.menuList.length === 0 && !['Login', 'Home'].includes(to.name)) {
    flag++
    await menusStore.getUserMenu()
    next({ path: to.fullPath })
  } else {
    // 否则直接放行
    next()
  }
})

// 全局后置钩子-常用于结束动画等
router.afterEach(() => {
  // 不接受next
})

export default router
