<template>
  <router-view/>
</template>
<script>
</script>
<style lang="scss">
// 去掉整个项目的最外层网页滚动条
// ::-webkit-scrollbar {
//   width: 0 !important;
// }
// ::-webkit-scrollbar {
//   width: 0 !important;height: 0;
// }
</style>
